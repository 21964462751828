import { BuildingBlockCategoryDto } from "../../dto/building-blocks/building-block-category.dto";
import { BuildingBlockCategory } from "../../models/building-blocks/building-block-category.model";

export class BuildingBlockCategoryMapper {
  public static toModel(dto: BuildingBlockCategoryDto): BuildingBlockCategory {
    return {
      id: dto.id,
      code: dto.code,
      title: dto.title,
      categoryType: dto.category_type
    }
  }
}