import { PlaceholderAnswerDto } from "../../dto/placeholders/placeholder-answer.dto";
import { PlaceholderAnswer } from "../../models/placeholders/placeholder-answer.model";

export class PlaceholderAnswerMapper {
  public static toModel(dto: PlaceholderAnswerDto): PlaceholderAnswer {
    return {
      id: dto.id,
      userId: dto.user_id,
      placeholderId: dto.placeholder_id,
      answer: dto.answer
    }
  }
}
