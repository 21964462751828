import { AudioFileDto } from "../../dto/media/audio-file.dto";
import { AudioFile } from "../../models/media/audio-file.model";

export class AudioFileMapper {
  public static toModel(dto: AudioFileDto): AudioFile {
    return {
      extension: dto.extension,
      name: dto.name,
      size: dto.size,
      duration: dto.duration,
      bucket: dto.bucket,
      key: dto.key
    }
  }

  public static toDto(model: AudioFile): AudioFileDto {
    return {
      extension: model.extension,
      name: model.name,
      size: model.size,
      duration: model.duration,
      bucket: model.bucket,
      key: model.key
    }
  }
}