import { SessionTemplateBlockUpdateDto } from "../../dto/sessions/session-template-block-update.dto";
import { SessionTemplateBlockDto } from "../../dto/sessions/session-template-block.dto";
import { SessionTemplateBlock } from "../../models/sessions/session-template-block.model";

export class SessionTemplateBlockMapper {
  public static toModel(dto: SessionTemplateBlockDto): SessionTemplateBlock {
    return {
      id: dto.id,
      blockTemplateId: dto.building_block_template.id,
      startTime: dto.start_time,
      endTime: dto.end_time,
      repeats: dto.repeats,
      reference: dto.id?.toString() || ''
    }
  }

  public static toUpdateDto(model: SessionTemplateBlock): SessionTemplateBlockUpdateDto {
    return {
      id: model.id? model.id : null,
      building_block_template_id: model.blockTemplateId,
      start_time: model.startTime,
      end_time: model.endTime,
      repeats: model.repeats
    }
  }
}