import { EnumDto } from "../../dto/enum.dto";
import { BlockSubType } from "../../models/building-blocks/building-block-template.model";
import { BuildingBlockSubType } from "../../models/building-blocks/building-block-sub-type.model";

export class BuildingBlockSubTypeMapper {
  public static toModel(dto: EnumDto): BuildingBlockSubType {
    return {
      id: dto.id,
      code: dto.description as BlockSubType,
      title: dto.description.split('_').map(s => s.charAt(0).toUpperCase() + s.slice(1)).join(' '),
    }
  }
}