import { Injectable } from "@angular/core";
import { StoreService } from "..";
import { VoiceApiService } from "../../services/api/voice-api.service";
import { catchError, map, Observable, tap } from "rxjs";
import { Voice } from "../../models/voices/voice.model";

@Injectable({
  providedIn: 'root'
})
export class VoiceService {
 
  constructor(
    private store: StoreService, 
    private voiceApiService: VoiceApiService
  ) {}

  get voices$(): Observable<Voice[]> {
    return this.store.state$.pipe(map(state => Object.values(state.voices)));
  }

  load(): Observable<Voice[]> {
    this.store.setState({ ...this.store.state, loading: true, error: null });
    return this.voiceApiService.getAll().pipe(
      tap((voices) => {
        const state = { ...this.store.state };
        voices.forEach((voice) => state.voices[voice.id] = voice);
        state.loading = false;
        this.store.setState(state);
      }),
      catchError((error) => {
        this.store.setState({ ...this.store.state, loading: false, error });
        console.error('Error loading voices:', error);
        throw error;
      })
    );
  }

  clear(): void {
    const state = { ...this.store.state };
    state.voices = {};
    this.store.setState(state);
  }
}