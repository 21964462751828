import { TimeSlotDto } from "../dto/time-slot.dto";
import { TimeSlot } from "../models/time-slot.model";

export class TimeSlotMapper {
  public static toModel(dto:TimeSlotDto): TimeSlot {
    return {
      id: dto.id,
      name: dto.name,
      startTime: dto.start_time,
      endTime: dto.end_time,
    }
  }
}