import { Injectable } from "@angular/core";
import { StoreService } from "..";
import { catchError, from, map, Observable, of, tap } from "rxjs";
import { SmartSurvey, SmartSurveyBase, SmartSurveyDetails } from "../../models/smart-programs/smart-survey.model";
import { SmartSurveyApiService } from "../../services/api/smart-survey-api.service";

@Injectable({
  providedIn: 'root'
})
export class SmartSurveyService {
 
  constructor(
    private store: StoreService, 
    private smartSurveyApiService: SmartSurveyApiService
  ) {}

  get smartSurveys$(): Observable<SmartSurvey[]> {
    return this.store.state$.pipe(map(state => Object.values(state.smartSurveys)));
  }

  load(): Observable<SmartSurvey[]> {
    this.store.setState({ ...this.store.state, loading: true, error: null });
    return this.smartSurveyApiService.getAll().pipe(
      tap((smartSurveys) => {
        const state = { ...this.store.state };
        smartSurveys.forEach((smartSurvey) => state.smartSurveys[smartSurvey.id] = smartSurvey);
        state.loading = false;
        this.store.setState(state);
      }),
      catchError((error) => {
        this.store.setState({ ...this.store.state, loading: false, error });
        console.error('Error loading SmartSurveys:', error);
        throw error;
      })
    );
  }

  loadDetails(id: number): Observable<SmartSurveyDetails> {
    const existingSurvey = this.store.state.smartSurveys[id];
    if (existingSurvey?.isDetailed) {
      return of(existingSurvey as SmartSurveyDetails);
    }

    this.store.setState({ ...this.store.state, loading: true, error: null });
    return from(this.smartSurveyApiService.get(id)).pipe(
      tap((smartSurvey) => {
        const state = { ...this.store.state };
        state.smartSurveys[smartSurvey.id] = smartSurvey;
        state.loading = false;
        this.store.setState(state);
      }),
      catchError((error) => {
        this.store.setState({ ...this.store.state, loading: false, error });
        console.error('Error loading SmartSurvey details:', error);
        throw error;
      })
    );
  }

  addSmartSurvey(smartSurvey: SmartSurveyBase, localeId: number): Observable<SmartSurveyDetails> {
    this.store.setState({ ...this.store.state, loading: true, error: null });
    return from(this.smartSurveyApiService.add(smartSurvey, localeId)).pipe(
      tap((smartSurvey) => {
        const state = { ...this.store.state };
        state.smartSurveys[smartSurvey.id] = smartSurvey;
        state.loading = false;
        this.store.setState(state);
      }),
      catchError((error) => {
        this.store.setState({ ...this.store.state, loading: false, error });
        console.error('Error adding SmartSurvey:', error);
        throw error;
      })
    );
  }

  updateSmartSurvey(smartSurvey: SmartSurveyDetails): Observable<SmartSurveyDetails> {
    //The update is only for text fields, which will be handled by translation service
    //But we do need to update the state
    const state = { ...this.store.state };
    state.smartSurveys[smartSurvey.id] = smartSurvey;
    this.store.setState(state);
    return of(smartSurvey);
  }

  deleteSmartSurvey(id: number): Observable<void> {
    this.store.setState({ ...this.store.state, loading: true, error: null });
    return from(this.smartSurveyApiService.delete(id)).pipe(
      tap(() => {
        const state = { ...this.store.state };
        delete state.smartSurveys[id];
        state.loading = false;
        this.store.setState(state);
      }),
      catchError((error) => {
        this.store.setState({ ...this.store.state, loading: false, error });
        console.error('Error deleting SmartSurvey:', error);
        throw error;
      })
    );
  }

  clear(): void {
    const state = { ...this.store.state };
    state.smartSurveys = {};
    this.store.setState(state);
  }
}