import { Injectable } from '@angular/core';
import { BaseApiService } from '../base-api.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '@beyou/environments/environment';
import { firstValueFrom, map, Observable } from 'rxjs';
import { PlaceholderGroup, PlaceholderGroupBase, PlaceholderGroupTranslation } from '../../models/placeholders/placeholder-group.model';
import { PlaceholderGroupMapper } from '../../mappers/placeholders/placeholder-group.mapper';
import { PlaceholderGroupDto } from '../../dto/placeholders/placeholder-group.dto';
import { PlaceholderAnswerDto } from '../../dto/placeholders/placeholder-answer.dto';
import { PlaceholderAnswerMapper } from '../../mappers/placeholders/placeholder-answer.mapper';
import { PlaceholderAnswer } from '../../models/placeholders/placeholder-answer.model';
import { PlaceholderGroupTranslationsDto } from '../../dto/placeholders/placeholder-group-translations.dto';
import { PlaceholderGroupTranslationMapper } from '../../mappers/placeholders/placeholder-group-translation.mapper';

@Injectable({
  providedIn: 'root'
})
export class PlaceholderGroupApiService extends BaseApiService {

  constructor(http: HttpClient) {
    super(http);
  }

  public getAll(): Observable<PlaceholderGroup[]> {
    const apiUrl = `${environment.adminApiBaseUrl}/placeholder-groups`;
    return this.getAllPagedData<PlaceholderGroupDto>(apiUrl).pipe(
      map((response) => {
        return response.map(item => {
          return PlaceholderGroupMapper.toModel(item);
        })
      })
    );
  }

  public async update(placeholderGroup: PlaceholderGroup): Promise<void> {
    console.log('Placeholder group updated: ', placeholderGroup.id);
  }

  public async add(placeholderGroup: PlaceholderGroupBase): Promise<PlaceholderGroup> {
    const apiUrl = `${environment.adminApiBaseUrl}/placeholder-groups`;
    const dto = PlaceholderGroupMapper.toCreateDto(placeholderGroup);
    return await firstValueFrom(this.http.post<PlaceholderGroupDto>(apiUrl, dto).pipe(
      map((response) => {
        console.debug('Placeholder group added: ', response.id);
        return PlaceholderGroupMapper.toModel(response);
      })
    ));
  }

  public async delete(id: number): Promise<void> {
    const apiUrl = `${environment.adminApiBaseUrl}/placeholder-groups/${id}`;
    await firstValueFrom(this.http.delete(apiUrl));
  }

  public getUserAnswers(placeholderGroupId: number, userId:string): Observable<PlaceholderAnswer[]> {
    const apiUrl = `${environment.adminApiBaseUrl}/placeholder-groups/${placeholderGroupId}/placeholders/answers?user_id=${userId}`;
    return this.getAllPagedData<PlaceholderAnswerDto>(apiUrl).pipe(
      map((response) => {
        return response.map(item => {
          return PlaceholderAnswerMapper.toModel(item);
        })
      })
    );
  }

  public async addUserAnswer(placeholderId:number, placeholderGroupId:number, answer: string): Promise<PlaceholderAnswer> {
    const apiUrl = `${environment.adminApiBaseUrl}/placeholder-groups/${placeholderGroupId}/placeholders/${placeholderId}/answers`;
    const dto = {
      answer: answer
    }
    return await firstValueFrom(this.http.post<PlaceholderAnswerDto>(apiUrl, dto).pipe(
      map((response) => {
        console.debug('Placeholder answer added: ', response.id);
        return PlaceholderAnswerMapper.toModel(response);
      })
    ));
  }

  public async updateUserAnswer(id:number, placeholderId:number, placeholderGroupId:number, answer: string): Promise<void> {
    const apiUrl = `${environment.adminApiBaseUrl}/placeholder-groups/${placeholderGroupId}/placeholders/${placeholderId}/answers/${id}`;
    const dto = {
      answer: answer
    };
    await firstValueFrom(this.http.put(apiUrl, dto));
  }

  public async getTranslations(id: number): Promise<PlaceholderGroupTranslation[]> {
    const apiUrl = `${environment.adminApiBaseUrl}/placeholder-groups/${id}/translations`;
    return await firstValueFrom(this.http.get<PlaceholderGroupTranslationsDto>(apiUrl).pipe(
      map((response) => {
        console.debug('Placeholder group translations retrieved: ', id);
        return response.translations.map( item => PlaceholderGroupTranslationMapper.toModel(item));
      })
    ));
  }

  public async updateTranslations(id: number, translations: PlaceholderGroupTranslation[]): Promise<void> {
    const apiUrl = `${environment.adminApiBaseUrl}/placeholder-groups/${id}/translations`;
    const dto = {
      translations: translations.map(item => PlaceholderGroupTranslationMapper.toDto(item))
    };
    await firstValueFrom(this.http.post(apiUrl, dto));
    console.debug('Placeholder group translations updated: ', id);
  }
}
