import { BuildingBlockTemplateTranslationDto } from "../../dto/building-blocks/building-block-template-translations.dto";
import { BuildingBlockTemplateTranslation } from "../../models/building-blocks/building-block-template.model";

export class BuildingBlockTemplateTranslationMapper {
  public static toModel(dto: BuildingBlockTemplateTranslationDto): BuildingBlockTemplateTranslation {
    return {
      localeId: dto.locale_id,
      title: dto.title,
      script: dto.script,
      llmInstructions: dto.llm_instructions
    }
  }

  public static toDto(dto: BuildingBlockTemplateTranslation): BuildingBlockTemplateTranslationDto {
    return {
      locale_id: dto.localeId,
      title: dto.title,
      script: dto.script,
      llm_instructions: dto.llmInstructions
    }
  }
}