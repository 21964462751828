import { Injectable } from "@angular/core";
import { StoreService } from "..";
import { catchError, map, Observable, tap } from "rxjs";
import { CategorizationApiService, Categorizations } from "../../services/api/categorization-api.service";
import { TherapeuticDomain } from "../../models/therapeutic-domain.model";
import { Locale } from "../../models/locale.model";
import { SessionCategory } from "../../models/sessions/session-category.model";
import { BuildingBlockPurpose } from "../../models/building-blocks/building-block-purpose.model";
import { BuildingBlockSubType } from "../../models/building-blocks/building-block-sub-type.model";
import { BuildingBlockBinauralBeatsRange } from "../../models/building-blocks/building-block-binaural-beats-range.model";
import { BuildingBlockBinauralBeatsFrequencyVariation } from "../../models/building-blocks/building-block-binaural-beats-frequency-variation.model";
import { BuildingBlockCategory } from "../../models/building-blocks/building-block-category.model";
import { TimeSlot } from "../../models/time-slot.model";
import { ProgramTemplateSessionFrequency } from "../../models/programs/program-template-session-frequency.model";
import { ProgramTemplateIntensityLevel } from "../../models/programs/program-template-intensity-level.model";

@Injectable({
  providedIn: 'root'
})
export class CategorizationService {

  constructor(
    private store: StoreService, 
    private categorizationApiService: CategorizationApiService
  ) {}

  get therapeuticDomains$(): Observable<TherapeuticDomain[]> {
    return this.store.state$.pipe(map(state => Object.values(state.therapeuticDomains)));
  }

  get locales$(): Observable<Locale[]> {
    return this.store.state$.pipe(map(state => Object.values(state.locales)));
  }

  get sessionCategories$(): Observable<SessionCategory[]> {
    return this.store.state$.pipe(map(state => Object.values(state.sessionCategories)));
  }

  get buildingBlockCategories$(): Observable<BuildingBlockCategory[]> {
    return this.store.state$.pipe(map(state => Object.values(state.buildingBlockCategories)));
  }

  get buildingBlockPurposes$(): Observable<BuildingBlockPurpose[]> {
    return this.store.state$.pipe(map(state => Object.values(state.buildingBlockPurposes)));
  }

  get buildingBlockSubTypes$(): Observable<BuildingBlockSubType[]> {
    return this.store.state$.pipe(map(state => Object.values(state.buildingBlockSubTypes)));
  }

  get binauralBeatsFrequencyVariations$(): Observable<BuildingBlockBinauralBeatsFrequencyVariation[]> {
    return this.store.state$.pipe(map(state => Object.values(state.buildingBlockBinauralBeatsFrequencyVariations)));
  }

  get binauralBeatsRanges$(): Observable<BuildingBlockBinauralBeatsRange[]> {
    return this.store.state$.pipe(map(state => Object.values(state.buildingBlockBinauralBeatsRanges)));
  }

  get programTemplateIntensityLevels$(): Observable<ProgramTemplateIntensityLevel[]> {
    return this.store.state$.pipe(map(state => Object.values(state.programTemplateIntensityLevels)));
  }

  get programTemplateSessionFrequencies$(): Observable<ProgramTemplateSessionFrequency[]> {
    return this.store.state$.pipe(map(state => Object.values(state.programTemplateSessionFrequencies)));
  }

  get timeSlots$(): Observable<TimeSlot[]> {
    return this.store.state$.pipe(map(state => Object.values(state.timeSlots)));
  }

  load(): Observable<Categorizations> {
    this.store.setState({ ...this.store.state, loading: true, error: null });
    return this.categorizationApiService.getCategorizations().pipe(
      tap(({ 
        therapeuticDomains, 
        locales, 
        sessionCategories,
        buildingBlockPurposes,
        buildingBlockSubTypes, 
        buildingBlockBinauralBeatsFrequencyVariations, 
        buildingBlockBinauralBeatsRanges,
        buildingBlockCategories,
        programTemplateIntensityLevels,
        programTemplateSessionFrequencies,
        timeSlots
      }) => {
        const state = { ...this.store.state };
        therapeuticDomains.forEach((therapeuticDomain) => state.therapeuticDomains[therapeuticDomain.id] = therapeuticDomain);
        locales.forEach((locale) => state.locales[locale.id] = locale);
        sessionCategories.forEach((sessionCategory) => state.sessionCategories[sessionCategory.id] = sessionCategory);
        buildingBlockPurposes.forEach((buildingBlockPurpose) => state.buildingBlockPurposes[buildingBlockPurpose.id] = buildingBlockPurpose);
        buildingBlockSubTypes.forEach((buildingBlockSubType) => state.buildingBlockSubTypes[buildingBlockSubType.id] = buildingBlockSubType);
        buildingBlockBinauralBeatsFrequencyVariations.forEach((buildingBlockBinauralBeatsFrequencyVariation) => state.buildingBlockBinauralBeatsFrequencyVariations[buildingBlockBinauralBeatsFrequencyVariation.id] = buildingBlockBinauralBeatsFrequencyVariation);
        buildingBlockBinauralBeatsRanges.forEach((buildingBlockBinauralBeatsRange) => state.buildingBlockBinauralBeatsRanges[buildingBlockBinauralBeatsRange.id] = buildingBlockBinauralBeatsRange);
        buildingBlockCategories.forEach((buildingBlockCategory) => state.buildingBlockCategories[buildingBlockCategory.id] = buildingBlockCategory);
        programTemplateIntensityLevels.forEach((programTemplateIntensityLevel) => state.programTemplateIntensityLevels[programTemplateIntensityLevel.id] = programTemplateIntensityLevel);
        programTemplateSessionFrequencies.forEach((programTemplateSessionFrequency) => state.programTemplateSessionFrequencies[programTemplateSessionFrequency.id] = programTemplateSessionFrequency);
        timeSlots.forEach((timeSlot) => state.timeSlots[timeSlot.id] = timeSlot);
        state.loading = false;
        this.store.setState(state);
      }),
      catchError((error) => {
        this.store.setState({ ...this.store.state, loading: false, error });
        console.error('Error loading categorizations:', error);
        throw error;
      })
    );
  }

  clear(): void {
    const state = { ...this.store.state };
    state.therapeuticDomains = {};
    state.locales = {};
    state.sessionCategories = {};
    state.buildingBlockCategories = {};
    state.buildingBlockPurposes = {};
    state.buildingBlockSubTypes = {};
    state.buildingBlockBinauralBeatsFrequencyVariations = {};
    state.buildingBlockBinauralBeatsRanges = {}; 
    state.programTemplateIntensityLevels = {};
    state.programTemplateSessionFrequencies = {};
    state.timeSlots = {};
    this.store.setState(state);
  }
}