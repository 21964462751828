import { SmartSurveyTranslationDto } from "../../dto/smart-programs/smart-survey-translations.dto";
import { SmartSurveyTranslation } from "../../models/smart-programs/smart-survey.model";
import { SmartProgramStructureMapper } from "./smart-program-structure.mapper";

export class SmartSurveyTranslationMapper {

  public static toModel(dto: SmartSurveyTranslationDto): SmartSurveyTranslation {
    const sp = SmartProgramStructureMapper.toModel(dto);
    return {
      ...sp,
      title: dto.title,
      localeId: dto.locale_id
    }
  }

  public static toDto(model: SmartSurveyTranslation): SmartSurveyTranslationDto {
    const sp = SmartProgramStructureMapper.toDto(model);
    return {
      ...sp,
      title: model.title,
      locale_id: model.localeId
    }
  }

}