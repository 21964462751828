import { SmartProgramStructureDto } from "../../dto/smart-programs/smart-program-structure.dto";
import { SmartProgramStructure } from "../../models/smart-programs/smart-program-structure.model";

export class SmartProgramStructureMapper {
  public static toModel(dto: SmartProgramStructureDto): SmartProgramStructure {
    return {
      situation: dto.situation,
      challenge1: dto.challenge1,
      target: dto.target,
      indicator: dto.indicator,
      mindset: dto.mindset,
      affirmations: dto.affirmations,
      beliefs: dto.beliefs,
      badBeliefs: dto.bad_beliefs,
      actions: dto.actions,
      habits: dto.habits,
      badHabits: dto.bad_habits,
      rewardMilestone: dto.reward_milestone,
      rewardsFreq: dto.rewards_freq,
      rewardEndGoal: dto.reward_end_goal,
      exceptions: dto.exceptions,
      tempTargetFreq: dto.temp_target_freq,
      tempTargetMilestone: dto.temp_target_milestone,
      timeline: dto.timeline,
      exploreSituation: dto.explore_situation,
      exploreChallenge1: dto.explore_challenge1,
      exploreChallenge2: dto.explore_challenge2,
      exploreChallenge3: dto.explore_challenge3,
      exploreTarget: dto.explore_target,
      exploreIndicator: dto.explore_indicator,
      exploreMindset: dto.explore_mindset,
      exploreAffirmations: dto.explore_affirmations,
      exploreBeliefs: dto.explore_beliefs,
      exploreBadBeliefs: dto.explore_bad_beliefs,
      exploreActions: dto.explore_actions,
      exploreHabits: dto.explore_habits,
      exploreBadHabits: dto.explore_bad_habits,
      exploreRewardMilestone: dto.explore_reward_milestone,
      exploreRewardsFreq: dto.explore_rewards_freq,
      exploreRewardEndGoal: dto.explore_reward_end_goal,
      exploreExceptions: dto.explore_exceptions,
      exploreTempTargetFreq: dto.explore_temp_target_freq,
      exploreTempTargetMilestone: dto.explore_temp_target_milestone,
      exploreTimeline: dto.explore_timeline,
    }
  }

  public static toDto(model: SmartProgramStructure): SmartProgramStructureDto {
    return {
      situation: model.situation,
      challenge1: model.challenge1,
      target: model.target,
      indicator: model.indicator,
      mindset: model.mindset,
      affirmations: model.affirmations,
      beliefs: model.beliefs,
      bad_beliefs: model.badBeliefs,
      actions: model.actions,
      habits: model.habits,
      bad_habits: model.badHabits,
      reward_milestone: model.rewardMilestone,
      rewards_freq: model.rewardsFreq,
      reward_end_goal: model.rewardEndGoal,
      exceptions: model.exceptions,
      temp_target_freq: model.tempTargetFreq,
      temp_target_milestone: model.tempTargetMilestone,
      timeline: model.timeline,
      explore_situation: model.exploreSituation,
      explore_challenge1: model.exploreChallenge1,
      explore_challenge2: model.exploreChallenge2,
      explore_challenge3: model.exploreChallenge3,
      explore_target: model.exploreTarget,
      explore_indicator: model.exploreIndicator,
      explore_mindset: model.exploreMindset,
      explore_affirmations: model.exploreAffirmations,
      explore_beliefs: model.exploreBeliefs,
      explore_bad_beliefs: model.exploreBadBeliefs,
      explore_actions: model.exploreActions,
      explore_habits: model.exploreHabits,
      explore_bad_habits: model.exploreBadHabits,
      explore_reward_milestone: model.exploreRewardMilestone,
      explore_rewards_freq: model.exploreRewardsFreq,
      explore_reward_end_goal: model.exploreRewardEndGoal,
      explore_exceptions: model.exploreExceptions,
      explore_temp_target_freq: model.exploreTempTargetFreq,
      explore_temp_target_milestone: model.exploreTempTargetMilestone,
      explore_timeline: model.exploreTimeline,
    }
  }
}