import { PlaceholderGroupCreateDto } from "../../dto/placeholders/placeholder-group-create.dto";
import { PlaceholderGroupDto } from "../../dto/placeholders/placeholder-group.dto";
import { PlaceholderGroup, PlaceholderGroupBase } from "../../models/placeholders/placeholder-group.model";

export class PlaceholderGroupMapper {
  public static toModel(dto: PlaceholderGroupDto): PlaceholderGroup {
    return {
      id: dto.id,
      code: dto.code,
      description: dto.description,
      title: dto.title
    }
  }

  public static toCreateDto(model: PlaceholderGroupBase): PlaceholderGroupCreateDto {
    return {
      code: model.code,
      description: model.description,
      title: model.title
    }
  }
}