import { Injectable } from "@angular/core";
import { StoreService } from "..";
import { catchError, from, map, Observable, tap } from "rxjs";
import { BuildingBlockTemplateApiService } from "../../services/api/building-block-template-api.service";
import { BuildingBlockTemplate, BuildingBlockTemplateBase } from "../../models/building-blocks/building-block-template.model";

@Injectable({
  providedIn: 'root'
})
export class BuildingBlockTemplateService {

  constructor(
    private store: StoreService,
    private buildingBlockTemplateApiService: BuildingBlockTemplateApiService
  ) { }

  get buildingBlockTemplates$(): Observable<BuildingBlockTemplate[]> {
    return this.store.state$.pipe(map(state => Object.values(state.buildingBlockTemplates)));
  }

  load(): Observable<BuildingBlockTemplate[]> {
    this.store.setState({ ...this.store.state, loading: true, error: null });
    return this.buildingBlockTemplateApiService.getAll().pipe(
      tap((templates) => {
        const state = { ...this.store.state };
        templates.forEach((t) => state.buildingBlockTemplates[t.id] = t);
        state.loading = false;
        this.store.setState(state);
      }),
      catchError((error) => {
        this.store.setState({ ...this.store.state, loading: false, error });
        console.error('Error loading building block templates:', error);
        throw error;
      })
    );
  }

  addBuildingBlockTemplate(template: BuildingBlockTemplateBase, localeCode: string): Observable<BuildingBlockTemplate> {
    this.store.setState({ ...this.store.state, loading: true, error: null });
    return from(this.buildingBlockTemplateApiService.add(template, localeCode)).pipe(
      tap((nbt) => {
        const state = { ...this.store.state };
        state.buildingBlockTemplates[nbt.id] = nbt;
        state.loading = false;
        this.store.setState(state);
      }),
      catchError((error) => {
        this.store.setState({ ...this.store.state, loading: false, error });
        console.error('Error adding building block template:', error);
        throw error;
      })
    );
  }

  updateBuildingBlockTemplate(template: BuildingBlockTemplate): Observable<BuildingBlockTemplate> {
    this.store.setState({ ...this.store.state, loading: true, error: null });
    return from(this.buildingBlockTemplateApiService.update(template)).pipe(
      tap((template) => {
        const state = { ...this.store.state };
        state.buildingBlockTemplates[template.id] = template;
        state.loading = false;
        this.store.setState(state);
      }),
      catchError((error) => {
        this.store.setState({ ...this.store.state, loading: false, error });
        console.error('Error updating building block template:', error);
        throw error;
      })
    );
  }

  deleteBuildingBlockTemplate(templateId: number): Observable<void> {
    this.store.setState({ ...this.store.state, loading: true, error: null });
    return from(this.buildingBlockTemplateApiService.delete(templateId)).pipe(
      tap(() => {
        const state = { ...this.store.state };
        delete state.buildingBlockTemplates[templateId];
        state.loading = false;
        this.store.setState(state);
      }),
      catchError((error) => {
        this.store.setState({ ...this.store.state, loading: false, error });
        console.error('Error deleting building block template:', error);
        throw error;
      })
    );
  }

  clear(): void {
    const state = { ...this.store.state };
    state.buildingBlockTemplates = {};
    this.store.setState(state);
  }
}