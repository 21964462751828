import { SessionTemplateCreateDto } from "../../dto/sessions/session-template-create.dto";
import { SessionTemplateUpdateDto } from "../../dto/sessions/session-template-update.dto";
import { SessionTemplateDto } from "../../dto/sessions/session-template.dto";
import { SessionTemplate, SessionTemplateBase } from "../../models/sessions/session-template.model";
import { CoverMapper } from "../media/cover.mapper";
import { SessionTemplateBlockMapper } from "./session-template-block.mapper";

export class SessionTemplateMapper {
  public static toModel(dto: SessionTemplateDto): SessionTemplate {
    return {
      id: dto.id,
      title: dto.title,
      originalLocaleId: dto.original_locale_id,
      subtitle: dto.subtitle,
      description: dto.description,
      isActive: dto.is_active,
      estimatedDuration: dto.estimated_duration || 0,
      //TODO: remove this, move to stats
      viewCount: 1000,
      categories: dto.categories.map(c => c.code),
      therapeuticDomainCode: dto.therapeutic_domain.code,
      creator: 'admin',
      cover: dto.cover? CoverMapper.toModel(dto.cover) : null,
      blocks: dto.blocks.map(b => SessionTemplateBlockMapper.toModel(b)),
      smartSurveyId: dto.smart_survey_id,
      updatedAt: new Date(dto.updated_at),
      createdAt: new Date(dto.created_at)
    }
  }

  public static toCreateDto(model: SessionTemplateBase, localeId: number): SessionTemplateCreateDto {
    return {
      title: model.title,
      subtitle: model.subtitle,
      description: model.description,
      locale_id: localeId,
      estimated_duration: model.estimatedDuration,
      therapeutic_domain: model.therapeuticDomainCode,
      categories: model.categories,
      cover: model.cover? CoverMapper.toDto(model.cover) : undefined,
      blocks: model.blocks.map(b => SessionTemplateBlockMapper.toUpdateDto(b)),
    }
  }

  public static toUpdateDto(model: SessionTemplate): SessionTemplateUpdateDto {
    return {
      smart_survey_id: model.smartSurveyId,
      cover: model.cover? CoverMapper.toDto(model.cover) : undefined,
      categories: model.categories,
      estimated_duration: model.estimatedDuration,
      blocks: model.blocks.map(b => SessionTemplateBlockMapper.toUpdateDto(b))
    }
  }
}