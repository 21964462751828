import { Injectable } from "@angular/core";
import { StoreService } from "..";
import { catchError, from, map, Observable, tap } from "rxjs";
import { PlaceholderGroupApiService } from "../../services/api/placeholder-group-api.service";
import { PlaceholderGroup, PlaceholderGroupBase } from "../../models/placeholders/placeholder-group.model";

@Injectable({
  providedIn: 'root'
})
export class PlaceholderGroupService {

  constructor(
    private store: StoreService, 
    private placeholderGroupApiService: PlaceholderGroupApiService
  ) {}

  get placeholderGroups$(): Observable<PlaceholderGroup[]> {
    return this.store.state$.pipe(map(state => Object.values(state.placeholderGroups)));
  }

  load(): Observable<PlaceholderGroup[]> {
    this.store.setState({ ...this.store.state, loading: true, error: null });
    return this.placeholderGroupApiService.getAll().pipe(
      tap((groups) => {
        const state = { ...this.store.state };
        groups.forEach((group) => state.placeholderGroups[group.id] = group);
        state.loading = false;
        this.store.setState(state);
      }),
      catchError((error) => {
        this.store.setState({ ...this.store.state, loading: false, error });
        console.error('Error loading placeholder groups:', error);
        throw error;
      })
    );
  }

  addPlaceholderGroup(group: PlaceholderGroupBase): Observable<PlaceholderGroup> {
    this.store.setState({ ...this.store.state, loading: true, error: null });
    return from(this.placeholderGroupApiService.add(group)).pipe(
      tap((newGroup) => {
        const state = { ...this.store.state };
        state.placeholderGroups[newGroup.id] = newGroup;
        state.loading = false;
        this.store.setState(state);
      }),
      catchError((error) => {
        this.store.setState({ ...this.store.state, loading: false, error });
        console.error('Error adding placeholder group:', error);
        throw error;
      })
    );
  }

  updatePlaceholderGroup(group: PlaceholderGroup): Observable<void> {
    this.store.setState({ ...this.store.state, loading: true, error: null });
    return from(this.placeholderGroupApiService.update(group)).pipe(
      tap(() => {
        const state = { ...this.store.state };
        state.placeholderGroups[group.id] = group;
        state.loading = false;
        this.store.setState(state);
      }),
      catchError((error) => {
        this.store.setState({ ...this.store.state, loading: false, error });
        console.error('Error updating placeholder group:', error);
        throw error;
      })
    );
  }

  deletePlaceholderGroup(groupId: number): Observable<void> {
    this.store.setState({ ...this.store.state, loading: true, error: null });
    return from(this.placeholderGroupApiService.delete(groupId)).pipe(
      tap(() => {
        const state = { ...this.store.state };
        delete state.placeholderGroups[groupId];
        state.loading = false;
        this.store.setState(state);
      }),
      catchError((error) => {
        this.store.setState({ ...this.store.state, loading: false, error });
        console.error('Error deleting placeholder group:', error);
        throw error;
      })
    );
  }

  clear(): void {
    const state = { ...this.store.state };
    state.placeholderGroups = {};
    this.store.setState(state);
  }
}