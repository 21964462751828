import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { Voice } from '../../models/voices/voice.model';
import { environment } from '@beyou/environments/environment';
import { BaseApiService } from '../base-api.service';
import { HttpClient } from '@angular/common/http';
import { VoiceMapper } from '../../mappers/voices/voice.mapper';
import { VoiceDto } from '../../dto/voices/voice.dto';

@Injectable({
  providedIn: 'root'
})
export class VoiceApiService extends BaseApiService {

  constructor(http: HttpClient) { 
    super(http);
  }

  public getAll(): Observable<Voice[]> {
    const apiUrl = `${environment.adminApiBaseUrl}/voices`;
    return this.getAllPagedData<VoiceDto>(apiUrl).pipe(
      map((response) => {
        console.debug('Voices retrieved: ', response);
        return response.map(item => {
          return VoiceMapper.toModel(item);
        })
      })
    );
  }
}
