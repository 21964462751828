import { SmartSurveyCreateDto } from "../../dto/smart-programs/smart-survey-create.dto";
import { SmartSurveyDetailsDto } from "../../dto/smart-programs/smart-survey-details.dto";
import { SmartSurveyDto } from "../../dto/smart-programs/smart-survey.dto";
import { SmartSurvey, SmartSurveyBase, SmartSurveyDetails } from "../../models/smart-programs/smart-survey.model";
import { SmartProgramStructureMapper } from "./smart-program-structure.mapper";

export class SmartSurveyMapper {
  public static toModel(dto: SmartSurveyDto): SmartSurvey {
    return {
      id: dto.id,
      originalLocaleId: dto.original_locale_id,
      title: dto.title,
      therapeuticDomainCode: dto.therapeutic_domain.code
    }
  }

  public static toDetailedModel(dto: SmartSurveyDetailsDto): SmartSurveyDetails {
    const sp = SmartProgramStructureMapper.toModel(dto);
    return {
      ...sp,
      id: dto.id,
      originalLocaleId: dto.original_locale_id,
      title: dto.title,
      therapeuticDomainCode: dto.therapeutic_domain.code,
      isDetailed: true
    }
  }

  public static toCreateDto(model: SmartSurveyBase, localeId:number): SmartSurveyCreateDto {
    const sp = SmartProgramStructureMapper.toDto(model);
    return {
      ...sp,
      title: model.title,
      therapeutic_domain: model.therapeuticDomainCode,
      locale_id: localeId
    }
  }

}