import { EnumDto } from "../dto/enum.dto";
import { ILabeledEntity } from "../interfaces/labeled-entity.interface";

export class EnumMapper {
  public static toModel<T>(dto: EnumDto): T {
    const e:ILabeledEntity = {
      id: dto.id,
      title: dto.description.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' '),
      code: dto.description
    }
    return e as T;
  }
}