import { BuildingBlockTemplateAudioTranslationDto } from "../../dto/building-blocks/building-block-template-audio-translations.dto";
import { BuildingBlockTemplateAudioTranslation } from "../../models/building-blocks/building-block-template.model";
import { AudioFileMapper } from "../media/audio-file.mapper";

export class BuildingBlockTemplateAudioTranslationMapper {
  public static toModel(dto: BuildingBlockTemplateAudioTranslationDto): BuildingBlockTemplateAudioTranslation {
    const audioFile = AudioFileMapper.toModel(dto);
    return {
      ...audioFile,
      localeId: dto.locale_id,
    }
  }

  public static toDto(dto: BuildingBlockTemplateAudioTranslation): BuildingBlockTemplateAudioTranslationDto {
    const audioFileDto = AudioFileMapper.toDto(dto);
    return {
      ...audioFileDto,
      locale_id: dto.localeId
    }
  }
}