import { Injectable } from "@angular/core";
import { StoreService } from "..";
import { catchError, from, map, Observable, tap } from "rxjs";
import { SessionTemplateApiService } from "../../services/api/session-template-api.service";
import { SessionTemplate, SessionTemplateBase } from "../../models/sessions/session-template.model";

@Injectable({
  providedIn: 'root'
})
export class SessionTemplateService {
 
  constructor(
    private store: StoreService, 
    private sessionTemplateApiService: SessionTemplateApiService
  ) {}

  get sessionTemplates$(): Observable<SessionTemplate[]> {
    return this.store.state$.pipe(map(state => Object.values(state.sessionTemplates)));
  }

  load(): Observable<SessionTemplate[]> {
    this.store.setState({ ...this.store.state, loading: true, error: null });
    return this.sessionTemplateApiService.getAll().pipe(
      tap((templates) => {
        const state = { ...this.store.state };
        templates.forEach((t) => state.sessionTemplates[t.id] = t);
        state.loading = false;
        this.store.setState(state);
      }),
      catchError((error) => {
        this.store.setState({ ...this.store.state, loading: false, error });
        console.error('Error loading building session templates:', error);
        throw error;
      })
    );
  }

  addSessionTemplate(sessionTemplate: SessionTemplateBase, localeId:number): Observable<SessionTemplate> {
    this.store.setState({ ...this.store.state, loading: true, error: null });
    return from(this.sessionTemplateApiService.add(sessionTemplate, localeId)).pipe(
      tap((newTemplate) => {
        const state = { ...this.store.state };
        state.sessionTemplates[newTemplate.id] = newTemplate;
        state.loading = false;
        this.store.setState(state);
      }),
      catchError((error) => {
        this.store.setState({ ...this.store.state, loading: false, error });
        console.error('Error adding session template:', error);
        throw error;
      })
    );
  }

  updateSessionTemplate(sessionTemplate: SessionTemplate): Observable<SessionTemplate> {
    this.store.setState({ ...this.store.state, loading: true, error: null });
    return from(this.sessionTemplateApiService.update(sessionTemplate)).pipe(
      tap((sessionTemplate) => {
        const state = { ...this.store.state };
        state.sessionTemplates[sessionTemplate.id] = sessionTemplate;
        state.loading = false;
        this.store.setState(state);
      }),
      catchError((error) => {
        this.store.setState({ ...this.store.state, loading: false, error });
        console.error('Error updating session template:', error);
        throw error;
      })
    );
  }

  deleteSessionTemplate(id: number): Observable<void> {
    this.store.setState({ ...this.store.state, loading: true, error: null });
    return from(this.sessionTemplateApiService.delete(id)).pipe(
      tap(() => {
        const state = { ...this.store.state };
        delete state.sessionTemplates[id];
        state.loading = false;
        this.store.setState(state);
      }),
      catchError((error) => {
        this.store.setState({ ...this.store.state, loading: false, error });
        console.error('Error deleting session template:', error);
        throw error;
      })
    );
  }

  clear(): void {
    const state = { ...this.store.state };
    state.sessionTemplates = {};
    this.store.setState(state);
  }
}