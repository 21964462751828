import { TherapeuticDomainDto } from "../dto/therapeutic-domain.dto";
import { TherapeuticDomain } from "../models/therapeutic-domain.model";

export class TherapeuticDomainMapper {
  public static toModel(dto: TherapeuticDomainDto): TherapeuticDomain {
    return {
      id: dto.id,
      code: dto.code,
      name: dto.name,
      position: dto.position
    }
  }
}