import { S3FileDto } from "../../dto/media/s3-file.dto";
import { S3File } from "../../models/media/s3-file.model";

export class S3FileMapper {
  public static toModel(dto: S3FileDto): S3File {
    return {
      bucket: dto.bucket,
      key: dto.key,
      size: dto.size || 0,
      extension: dto.extension,
      region: dto.region
    }
  }

  public static toDto(model: S3File): S3FileDto {
    return {
      bucket: model.bucket,
      key: model.key,
      size: model.size,
      extension: model.extension,
      region: model.region
    }
  }
}