import { SessionTemplateTranslationDto } from "../../dto/sessions/session-template-translations.dto";
import { SessionTemplateTranslation } from "../../models/sessions/session-template.model";

export class SessionTemplateTranslationMapper {
  public static toModel(dto: SessionTemplateTranslationDto): SessionTemplateTranslation {
    return {
      localeId: dto.locale_id,
      title: dto.title,
      subtitle: dto.subtitle,
      description: dto.description
    }
  }

  public static toDto(dto: SessionTemplateTranslation): SessionTemplateTranslationDto {
    return {
      locale_id: dto.localeId,
      title: dto.title,
      subtitle: dto.subtitle,
      description: dto.description
    }
  }
}