import { SessionCategoryIconsBasePath } from "../../constants/paths";
import { SessionCategoryDto } from "../../dto/sessions/session-category.dto";
import { SessionCategory } from "../../models/sessions/session-category.model";

const sessionCategoryStyling = [
  { id: 'focus', image: `${SessionCategoryIconsBasePath}/Circle.svg`, backgroundColor: 'rgba(84, 88, 247, 0.1)' },
  { id: 'stress', image: `${SessionCategoryIconsBasePath}/RainbowCloud.svg`, backgroundColor: 'rgba(47, 225, 172, 0.1)' },
  { id: 'tension', image: `${SessionCategoryIconsBasePath}/Spiral.svg`, backgroundColor: 'rgba(247, 143, 84, 0.1)' },
];

export class SessionCategoryMapper {
  public static toModel(dto: SessionCategoryDto): SessionCategory {
    const i = dto.id % 3;
    return {
      id: dto.id,
      code: dto.code,
      title: dto.title,
      image: sessionCategoryStyling[i].image,
      backgroundColor: sessionCategoryStyling[i].backgroundColor
    }
  }
}