import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApiService } from '../base-api.service';
import { firstValueFrom, map, Observable } from 'rxjs';
import { BuildingBlockTemplate, BuildingBlockTemplateAudioTranslation, BuildingBlockTemplateBase, BuildingBlockTemplateTranslation } from '../../models/building-blocks/building-block-template.model';
import { environment } from '@beyou/environments/environment';
import { BuildingBlockTemplateDto } from '../../dto/building-blocks/building-block-template.dto';
import { BuildingBlockTemplateMapper } from '../../mappers/building-blocks/building-block-template.mapper';
import { BuildingBlockTemplateTranslationsDto } from '../../dto/building-blocks/building-block-template-translations.dto';
import { BuildingBlockTemplateTranslationMapper } from '../../mappers/building-blocks/building-block-template-translation.mapper';
import { BuildingBlockTemplateAudioTranslationsDto } from '../../dto/building-blocks/building-block-template-audio-translations.dto';
import { BuildingBlockTemplateAudioTranslationMapper } from '../../mappers/building-blocks/building-block-template-audio-translation.mapper';

type ExportResult = {
  key: string;
  bucket: string;
}

@Injectable({
  providedIn: 'root'
})
export class BuildingBlockTemplateApiService extends BaseApiService {

  constructor(http: HttpClient) {
    super(http);
  }

  public getAll(): Observable<BuildingBlockTemplate[]> {
    const apiUrl = `${environment.adminApiBaseUrl}/buildingblock-templates`;
    return this.getAllPagedData<BuildingBlockTemplateDto>(apiUrl).pipe(
      map((response) => {
        return response.map(item => {
          return BuildingBlockTemplateMapper.toModel(item);
        })
      })
    );
  }

  public async delete(id: number): Promise<void> {
    const apiUrl = `${environment.adminApiBaseUrl}/buildingblock-templates/${id}`;
    await firstValueFrom(this.http.delete(apiUrl));
    console.debug('Building block document deleted: ', id);
  }

  public async update(buildingBlock: BuildingBlockTemplate): Promise<BuildingBlockTemplate> {
    const apiUrl = `${environment.adminApiBaseUrl}/buildingblock-templates/${buildingBlock.id}`;
    const dto = BuildingBlockTemplateMapper.toUpdateDto(buildingBlock);
    return await firstValueFrom(this.http.put<BuildingBlockTemplateDto>(apiUrl, dto).pipe(
      map((response) => {
        console.debug('Building block updated: ', response.id);
        return BuildingBlockTemplateMapper.toModel(response);
      })
    ));
  }

  public async add(buildingBlock: BuildingBlockTemplateBase, localeCode: string): Promise<BuildingBlockTemplate> {
    const apiUrl = `${environment.adminApiBaseUrl}/buildingblock-templates`;
    const dto = BuildingBlockTemplateMapper.toCreateDto(buildingBlock, localeCode);
    return await firstValueFrom(this.http.post<BuildingBlockTemplateDto>(apiUrl, dto).pipe(
      map((response) => {
        console.debug('Building block template added: ', response.id);
        return BuildingBlockTemplateMapper.toModel(response);
      })
    ));
  }

  public async getAvailablePlaceholders(placeholderGroupId?:number): Promise<string[]> {
    let apiUrl = `${environment.adminApiBaseUrl}/buildingblock-templates/placeholders/available`;
    let uriParams = new HttpParams();
    if(placeholderGroupId) {
      uriParams = uriParams.set('placeholder_group_id', placeholderGroupId.toString());
    }
    return await firstValueFrom(this.http.get<string[]>(apiUrl, { params: uriParams }));
  }

  public async export(localeId:number) : Promise<ExportResult> {
    const apiUrl = `${environment.adminApiBaseUrl}/buildingblock-templates/export?locale_id=${localeId}`;
    return await firstValueFrom(this.http.get<ExportResult>(apiUrl));
  }

  public async import(file:File) : Promise<void> {
    const uploadData = new FormData();
    uploadData.append('file', file);
    const apiUrl = `${environment.adminApiBaseUrl}/buildingblock-templates/import?is_dry_run=false`;
    await firstValueFrom(this.http.post(apiUrl, uploadData));
  }

  public async getTranslations(id: number): Promise<BuildingBlockTemplateTranslation[]> {
    const apiUrl = `${environment.adminApiBaseUrl}/buildingblock-templates/${id}/translations`;
    return await firstValueFrom(this.http.get<BuildingBlockTemplateTranslationsDto>(apiUrl).pipe(
      map((response) => {
        console.debug('Block template translations retrieved: ', id);
        return response.translations.map( item => BuildingBlockTemplateTranslationMapper.toModel(item));
      })
    ));
  }

  public async updateTranslations(id: number, translations: BuildingBlockTemplateTranslation[]): Promise<void> {
    const apiUrl = `${environment.adminApiBaseUrl}/buildingblock-templates/${id}/translations`;
    const dto = {
      translations: translations.map(item => BuildingBlockTemplateTranslationMapper.toDto(item))
    };
    await firstValueFrom(this.http.post(apiUrl, dto));
    console.debug('Block template translations updated: ', id);
  }

  public async getAudioTranslations(id: number): Promise<BuildingBlockTemplateAudioTranslation[]> {
    const apiUrl = `${environment.adminApiBaseUrl}/buildingblock-templates/${id}/audio-translations`;
    return await firstValueFrom(this.http.get<BuildingBlockTemplateAudioTranslationsDto>(apiUrl).pipe(
      map((response) => {
        console.debug('Block template audio translations retrieved: ', id);
        return response.translations.map( item => BuildingBlockTemplateAudioTranslationMapper.toModel(item));
      })
    ));
  }

  public async updateAudioTranslations(id: number, translations: BuildingBlockTemplateAudioTranslation[]): Promise<void> {
    const apiUrl = `${environment.adminApiBaseUrl}/buildingblock-templates/${id}/audio-translations`;
    const dto = {
      translations: translations.map(item => BuildingBlockTemplateAudioTranslationMapper.toDto(item))
    };
    await firstValueFrom(this.http.post(apiUrl, dto));
    console.debug('Block template audio translations updated: ', id);
  }

}
