import { Injectable } from '@angular/core';
import { BaseApiService } from '../base-api.service';
import { HttpClient } from '@angular/common/http';
import { SmartSurvey, SmartSurveyBase, SmartSurveyDetails, SmartSurveyTranslation } from '../../models/smart-programs/smart-survey.model';
import { firstValueFrom, map, Observable } from 'rxjs';
import { environment } from '@beyou/environments/environment';
import { SmartSurveyDto } from '../../dto/smart-programs/smart-survey.dto';
import { SmartSurveyMapper } from '../../mappers/smart-programs/smart-survey.mapper';
import { SmartSurveyDetailsDto } from '../../dto/smart-programs/smart-survey-details.dto';
import { SmartSurveyTranslationsDto } from '../../dto/smart-programs/smart-survey-translations.dto';
import { SmartSurveyTranslationMapper } from '../../mappers/smart-programs/smart-survey-translation.mapper';

@Injectable({
  providedIn: 'root'
})
export class SmartSurveyApiService extends BaseApiService {

  constructor(http: HttpClient) {
    super(http);
  }

  public getAll(): Observable<SmartSurvey[]> {
    const apiUrl = `${environment.adminApiBaseUrl}/smart-surveys`;
    return this.getAllPagedData<SmartSurveyDto>(apiUrl).pipe(
      map((response) => {
        return response.map(item => {
          return SmartSurveyMapper.toModel(item);
        })
      })
    );
  }

  public async get(id:number): Promise<SmartSurveyDetails> {
    const apiUrl = `${environment.adminApiBaseUrl}/smart-surveys/${id}`;
    return await firstValueFrom(this.http.get<SmartSurveyDetailsDto>(apiUrl).pipe(
      map((response) => {
        console.debug('Smart survey retrieved: ', response.id);
        return SmartSurveyMapper.toDetailedModel(response);
      })
    ));
  }

  public async add(survey:SmartSurveyBase, localeId:number): Promise<SmartSurveyDetails> {
    const apiUrl = `${environment.adminApiBaseUrl}/smart-surveys`;
    const dto = SmartSurveyMapper.toCreateDto(survey, localeId);
    return await firstValueFrom(this.http.post<SmartSurveyDetailsDto>(apiUrl, dto).pipe(
      map((response) => {
        console.debug('Survey added: ', response.id);
        return SmartSurveyMapper.toDetailedModel(response);
      })
    ));
  }

  public async delete(id: number): Promise<void> {
    const apiUrl = `${environment.adminApiBaseUrl}/smart-surveys/${id}`;
    await firstValueFrom(this.http.delete(apiUrl));
  }

  public async getTranslations(id: number): Promise<SmartSurveyTranslation[]> {
    const apiUrl = `${environment.adminApiBaseUrl}/smart-surveys/${id}/translations`;
    return await firstValueFrom(this.http.get<SmartSurveyTranslationsDto>(apiUrl).pipe(
      map((response) => {
        console.debug('Smart survey translations retrieved: ', id);
        return response.translations.map( item => SmartSurveyTranslationMapper.toModel(item));
      })
    ));
  }

  public async updateTranslations(id: number, translations: SmartSurveyTranslation[]): Promise<void> {
    const apiUrl = `${environment.adminApiBaseUrl}/smart-surveys/${id}/translations`;
    const dto = {
      translations: translations.map(item => SmartSurveyTranslationMapper.toDto(item))
    };
    await firstValueFrom(this.http.post(apiUrl, dto));
    console.debug('Smart survey translations updated: ', id);
  }

}
