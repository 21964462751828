import { PlaceholderGroupTranslationDto } from "../../dto/placeholders/placeholder-group-translations.dto";
import { PlaceholderGroupTranslation } from "../../models/placeholders/placeholder-group.model";

export class PlaceholderGroupTranslationMapper {
  public static toModel(dto: PlaceholderGroupTranslationDto): PlaceholderGroupTranslation {
    return {
      localeId: dto.locale_id,
      description: dto.description,
      title: dto.title
    }
  }

  public static toDto(model: PlaceholderGroupTranslation): PlaceholderGroupTranslationDto {
    return {
      locale_id: model.localeId,
      description: model.description,
      title: model.title
    }
  }
}