import { Injectable } from '@angular/core';
import { BaseApiService } from '../base-api.service';
import { HttpClient } from '@angular/common/http';
import { SessionTemplateDto } from '../../dto/sessions/session-template.dto';
import { environment } from '@beyou/environments/environment';
import { firstValueFrom, map, Observable } from 'rxjs';
import { SessionTemplate, SessionTemplateBase, SessionTemplateTranslation } from '../../models/sessions/session-template.model';
import { SessionTemplateMapper } from '../../mappers/sessions/session-template.mapper';
import { SessionTemplateTranslationsDto } from '../../dto/sessions/session-template-translations.dto';
import { SessionTemplateTranslationMapper } from '../../mappers/sessions/session-template-translation.mapper';

@Injectable({
  providedIn: 'root'
})
export class SessionTemplateApiService extends BaseApiService {

  constructor(http: HttpClient) { 
    super(http);
  }

  public getAll(): Observable<SessionTemplate[]> {
    const apiUrl = `${environment.adminApiBaseUrl}/session-templates`;
    return this.getAllPagedData<SessionTemplateDto>(apiUrl).pipe(
      map((response) => {
        return response.map(item => {
          return SessionTemplateMapper.toModel(item);
        })
      })
    );
  }

  public async update(sessionTemplate: SessionTemplate): Promise<SessionTemplate> {
    const apiUrl = `${environment.adminApiBaseUrl}/session-templates/${sessionTemplate.id}`;
    const dto = SessionTemplateMapper.toUpdateDto(sessionTemplate);
    return await firstValueFrom(this.http.put<SessionTemplateDto>(apiUrl, dto).pipe(
      map((response) => {
        console.debug('session template updated: ', response.id);
        return SessionTemplateMapper.toModel(response);
      })
    ));
  }

  public async add(sessionTemplate: SessionTemplateBase, localeId: number): Promise<SessionTemplate> {
    const apiUrl = `${environment.adminApiBaseUrl}/session-templates`;
    const dto = SessionTemplateMapper.toCreateDto(sessionTemplate, localeId);
    return await firstValueFrom(this.http.post<SessionTemplateDto>(apiUrl, dto).pipe(
      map((response) => {
        console.debug('session template added: ', response.id);
        return SessionTemplateMapper.toModel(response);
      })
    ));
  }

  public async delete(id: number): Promise<void> {
    const apiUrl = `${environment.adminApiBaseUrl}/session-templates/${id}`;
    await firstValueFrom(this.http.delete(apiUrl));
    console.log('Session template deleted: ', id);
  }

  public async getTranslations(id: number): Promise<SessionTemplateTranslation[]> {
    const apiUrl = `${environment.adminApiBaseUrl}/session-templates/${id}/translations`;
    return await firstValueFrom(this.http.get<SessionTemplateTranslationsDto>(apiUrl).pipe(
      map((response) => {
        console.debug('Session template translations retrieved: ', id);
        return response.translations.map( item => SessionTemplateTranslationMapper.toModel(item));
      })
    ));
  }

  public async updateTranslations(id: number, translations: SessionTemplateTranslation[]): Promise<void> {
    const apiUrl = `${environment.adminApiBaseUrl}/session-templates/${id}/translations`;
    const dto = {
      translations: translations.map(item => SessionTemplateTranslationMapper.toDto(item))
    };
    await firstValueFrom(this.http.post(apiUrl, dto));
    console.debug('Session template translations updated: ', id);
  }

}
