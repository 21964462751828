import { Injectable } from '@angular/core';
import { BaseApiService } from '../base-api.service';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { TherapeuticDomain } from '../../models/therapeutic-domain.model';
import { Locale } from '../../models/locale.model';
import { SessionCategory } from '../../models/sessions/session-category.model';
import { BuildingBlockPurpose } from '../../models/building-blocks/building-block-purpose.model';
import { BuildingBlockSubType } from '../../models/building-blocks/building-block-sub-type.model';
import { BuildingBlockBinauralBeatsFrequencyVariation } from '../../models/building-blocks/building-block-binaural-beats-frequency-variation.model';
import { BuildingBlockBinauralBeatsRange } from '../../models/building-blocks/building-block-binaural-beats-range.model';
import { environment } from '@beyou/environments/environment';
import { CategorizationsResponse } from '../../dto/categorizations-response';
import { BuildingBlockSubTypeMapper } from '../../mappers/building-blocks/building-block-sub-type.mapper';
import { SessionCategoryMapper } from '../../mappers/sessions/session-category.mapper';
import { LocaleMapper } from '../../mappers/locale.mapper';
import { TherapeuticDomainMapper } from '../../mappers/therapeutic-domain.mapper';
import { BuildingBlockCategoryMapper } from '../../mappers/building-blocks/building-block-category.mapper';
import { EnumMapper } from '../../mappers/enum.mapper';
import { BuildingBlockCategory } from '../../models/building-blocks/building-block-category.model';
import { TimeSlotMapper } from '../../mappers/time-slot.mapper';
import { ProgramTemplateIntensityLevel } from '../../models/programs/program-template-intensity-level.model';
import { ProgramTemplateSessionFrequency } from '../../models/programs/program-template-session-frequency.model';
import { TimeSlot } from '../../models/time-slot.model';

export type Categorizations = {
  therapeuticDomains: TherapeuticDomain[],
  locales: Locale[],
  sessionCategories: SessionCategory[],
  buildingBlockPurposes: BuildingBlockPurpose[],
  buildingBlockSubTypes: BuildingBlockSubType[],
  buildingBlockBinauralBeatsFrequencyVariations: BuildingBlockBinauralBeatsFrequencyVariation[],
  buildingBlockBinauralBeatsRanges: BuildingBlockBinauralBeatsRange[],
  buildingBlockCategories: BuildingBlockCategory[],
  programTemplateIntensityLevels: ProgramTemplateIntensityLevel[],
  programTemplateSessionFrequencies: ProgramTemplateSessionFrequency[],
  timeSlots: TimeSlot[]
}

@Injectable({
  providedIn: 'root'
})
export class CategorizationApiService extends BaseApiService {

  constructor(http: HttpClient) { 
    super(http);
  }

  public getCategorizations(): Observable<Categorizations> {
    const apiUrl = `${environment.adminApiBaseUrl}/categorizations`;
    return this.http.get<CategorizationsResponse>(apiUrl).pipe(
      map(response => {
        const therapeuticDomains = response.therapeutic_domains.map(item => TherapeuticDomainMapper.toModel(item));
        const locales = response.locales.map(item => LocaleMapper.toModel(item));
        const sessionCategories = response.session_categories.map(item => SessionCategoryMapper.toModel(item));
        const buildingBlockCategories = response.building_block_categories.map(item => BuildingBlockCategoryMapper.toModel(item));
        const buildingBlockPurposes = response.building_block_purposes.map(item => EnumMapper.toModel<BuildingBlockPurpose>(item));
        //const buildingBlockMainTypes = response.building_block_main_types.map(item => BuildingBlockMainTypeMapper.toModel(item));
        const buildingBlockSubTypes = response.building_block_sub_types.map(item =>  BuildingBlockSubTypeMapper.toModel(item));
        const buildingBlockBinauralBeatsFrequencyVariations = response.building_block_binaural_beats_frequency_variations.map(item => EnumMapper.toModel<BuildingBlockBinauralBeatsFrequencyVariation>(item));
        const buildingBlockBinauralBeatsRanges = response.building_block_binaural_beats_ranges.map(item => EnumMapper.toModel<BuildingBlockBinauralBeatsRange>(item));
        const programTemplateIntensityLevels = response.program_intensity_levels.map(item => EnumMapper.toModel<ProgramTemplateIntensityLevel>(item));
        const programTemplateSessionFrequencies = response.program_session_frequencies.map(item => EnumMapper.toModel<ProgramTemplateSessionFrequency>(item));
        const timeSlots = response.time_slots.map(item => TimeSlotMapper.toModel(item));
        
        return { 
          therapeuticDomains, 
          locales, 
          sessionCategories, 
          buildingBlockPurposes,
          buildingBlockSubTypes,
          buildingBlockBinauralBeatsFrequencyVariations,
          buildingBlockBinauralBeatsRanges,
          buildingBlockCategories,
          programTemplateIntensityLevels,
          programTemplateSessionFrequencies,
          timeSlots
        };
      })
    );
  }
}
